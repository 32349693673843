import {applyMiddleware, combineReducers, createStore} from 'redux'

import {addPeriodReducer, getPeriodReducer} from './reducers/periodReducers'
import {composeWithDevTools} from 'redux-devtools-extension'
import thunk from 'redux-thunk';

const reducer = combineReducers({
    addPeriod: addPeriodReducer,
    periodDetails: getPeriodReducer
})

// const cartItemsFromStorage = localStorage.getItem('cartItems')
//     ? JSON.parse(localStorage.getItem('cartItems'))
//     : []

const initialState = {
    // period: [
    //     moment().startOf('month').format('DD/MM/YYYY'),
    //     moment().endOf('month').format('DD/MM/YYYY')
    // ],
}
const middleware = [thunk]
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store;