import {
    ADD_PERIOD, GET_PERIOD_REQUEST, GET_PERIOD_REQUEST_FAIL, GET_PERIOD_REQUEST_SUCCESS
} from '../constants/periodConstants';

export const setPeriod = (period) => async (dispatch) => {
    dispatch({
        type: ADD_PERIOD,
        payload: period
    })
    localStorage.setItem('period', JSON.stringify(period))
}

export const getPeriod = () => async (dispatch) => {
    dispatch({type: GET_PERIOD_REQUEST})
    try {
        const period = localStorage.getItem('period')
        dispatch({ type: GET_PERIOD_REQUEST_SUCCESS, payload: JSON.parse(period) })
    } catch (e) {
        dispatch({
            type: GET_PERIOD_REQUEST_FAIL,
            payload: e.response && e.response.data.message
                ? e.response.data.message
                : e.message
        })
    }
}