import {
    ADD_PERIOD,
    GET_PERIOD_REQUEST,
    GET_PERIOD_REQUEST_SUCCESS,
    GET_PERIOD_REQUEST_FAIL,
} from '../constants/periodConstants';

export const addPeriodReducer = (state = {period: []}, action) => {
    switch (action.type) {
        case ADD_PERIOD:
            return { period: action.payload }
        default:
            return state
    }
}

export const getPeriodReducer = (state = {period: []}, action) =>{
    switch (action.type){
        case GET_PERIOD_REQUEST:
            return {period: []}
        case GET_PERIOD_REQUEST_SUCCESS:
            return { period: action.payload }
        case GET_PERIOD_REQUEST_FAIL:
            return { error: action.payload }
        default:
            return state
    }
}