import Statistics from './page/statistics';
import {Routes, Route} from "react-router-dom";
import SpecsStatistics from "./page/specs_statistics";


function App() {
    return (
        <div className="App">
            {/*<Statistics/>*/}
            <Routes>
                <Route path="/" element={<Statistics/>}/>
                <Route path="/statistics" element={<Statistics/>}/>
                <Route path="/statistics/:entity/:id" element={<SpecsStatistics/>}/>
            </Routes>
        </div>
    );
}

export default App;
