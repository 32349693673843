import React, {useState, useEffect} from 'react';
import './style.css'
import {
    DataGrid,
    GridToolbarContainer, GridToolbarExport,
    GridToolbarFilterButton
} from '@mui/x-data-grid';
import moment from "moment";
import DatePicker from "react-multi-date-picker";
import axios from "axios";
import PropTypes from "prop-types";
import {useParams, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import {getPeriod, setPeriod} from "../actions/periodActions";
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import frLocale from 'date-fns/locale/fr';

const CustomToolbar = ({setFilterButtonEl}) => (
    <GridToolbarContainer>
        <GridToolbarFilterButton ref={setFilterButtonEl}/>
        <GridToolbarExport/>
    </GridToolbarContainer>
);

CustomToolbar.propTypes = {
    setFilterButtonEl: PropTypes.func.isRequired,
};

export default function SpecsStatistics() {
    const dispatch = useDispatch()
    const [value, setValue] = useState([null, null]);
    let {entity, id} = useParams()

    const [filterButtonEl, setFilterButtonEl] = useState(null);
    const [pageSize, setPageSize] = useState(10);

    const [data, setData] = useState([])

    useEffect(() => {
        dispatch(getPeriod())
        let p = JSON.parse(localStorage.getItem('period'))
        // setValue([moment(p[0]).add(-1, 'days'), moment(p[1]).add(-1, 'days')])
        setValue(p)

        fetchStatsByTracks(p).then(res => {
            setData(res.data)
        }).catch(err => {
            console.log('Error: ', err)
        })
    }, [dispatch])

    const columns = [
        {
            field: 'entity',
            headerName: 'Nom',
            flex: 5,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div className="userListUser">
                        <img className="userListImg" src={params.row.avatar_url} alt=""/>
                        <p className="userListUsername word--wrap">{params.row.entity}</p>
                    </div>
                );
            },
        },
        {
            field: 'slug',
            headerName: 'Slug',
            flex: 5,
            disableColumnMenu: true,
        },
        {
            field: 'count',
            headerName: 'Count',
            flex: 1,
            disableColumnMenu: true,
        },
    ];


    const fetchStatsByTracks = async (p) => {
        return await axios.get(`/api/v1/stats/${entity}/${id}`, {
            params: {
                s: p[0],
                e: p[1],
            }
        })
    }

    const handlePeriodChange = () => {
        // dispatch(setPeriod([moment(value[0]).add(1, 'days'), moment(value[1]).add(1, 'days')]))
        dispatch(setPeriod(value))
        fetchStatsByTracks(value).then(res => {
            setData(res.data)
        }).catch(err => {
            console.log('Error: ', err)
        })
    }

    return (
        <>
            <div className="navbar">
                <h1 className='logo'>DingaStream</h1>

                <div className="flex ml-auto">
                    <button className="btn-outline">LOGIN</button>
                    <button className="btn-primary">S'INSCRIRE</button>
                </div>
            </div>
            <div className='page'>
                <h1 className=''>Statistiques par tracks </h1>
                <p>{localStorage.getItem('tag').slice(0, -1)} {localStorage.getItem('name')}</p>
                <div className="flex my-1 items-center">
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                        <DateRangePicker
                            startText="Début"
                            endText="Fin"
                            inputFormat="dd/MM/yyyy"
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            onClose={handlePeriodChange}
                            renderInput={(startProps, endProps) => {
                                return (
                                    <React.Fragment>
                                        <TextField {...startProps}/>
                                        <Box sx={{mx: 2}}> - </Box>
                                        <TextField {...endProps}/>
                                    </React.Fragment>
                                )
                            }}
                        />
                    </LocalizationProvider>
                </div>
                <div className="mt-4">
                    <DataGrid
                        rows={data}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        autoHeight={true}
                        // checkboxSelection={true}
                        disableColumnFilter={false}
                        components={{Toolbar: CustomToolbar}}
                        initialState={{
                            sorting: {
                                sortModel: [{field: 'count', sort: 'desc'}],
                            },
                        }}
                        componentsProps={{
                            panel: {
                                anchorEl: filterButtonEl,
                            },
                            toolbar: {
                                setFilterButtonEl,
                            },
                        }}
                    />
                </div>
            </div>
        </>
    );
}
