import React, {useState, useEffect, useCallback, useMemo} from 'react';
import './style.css'
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer, GridToolbarExport,
    GridToolbarFilterButton
} from '@mui/x-data-grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import moment from "moment";
import axios from "axios";
import PropTypes from "prop-types";
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from "react-redux";
import {getPeriod, setPeriod} from "../actions/periodActions";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateRangePicker from "@mui/lab/DateRangePicker";
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';
import frLocale from 'date-fns/locale/fr';

const CustomToolbar = ({setFilterButtonEl}) => (
    <GridToolbarContainer>
        <GridToolbarFilterButton ref={setFilterButtonEl}/>
        <GridToolbarExport/>
    </GridToolbarContainer>
);

CustomToolbar.propTypes = {
    setFilterButtonEl: PropTypes.func.isRequired,
};

const Statistics = (props) => {
    let by = 'tracks'
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const {period} = useSelector(state =>state.periodDetails)
    let val = period.length ? period : [
        moment().startOf('month'),
        moment().endOf('month')
    ]
    const [value, setValue] = useState(val);

    const [filterButtonEl, setFilterButtonEl] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [tag, setTag] = useState(by)
    const [hideActions, setHideActions] = useState(false)

    const [data, setData] = useState([])

    useEffect(() => {
        dispatch(setPeriod(value))
        fetchGlobalStats(value, by).then(res => {
            setData(res.data)
        }).catch(err => {
            console.log('Error: ', err)
        })
    }, [dispatch])

    const handleDetails = useCallback(
        (id, name) => () => {
            console.log(name, 88888888)
            localStorage.setItem('name', name)
            navigate({
                pathname: `/statistics/${localStorage.getItem('tag')}/${id}`
            })
        },
        [],
    );

    const columns = useMemo(
        () => [
            {
                field: 'entity',
                headerName: 'Nom',
                flex: 5,
                disableColumnMenu: true,
                renderCell: (params) => {
                    return (
                        <div className="userListUser">
                            <img className="userListImg" src={params.row.avatar_url} alt=""/>
                            <p className="userListUsername word--wrap">{params.row.entity}</p>
                        </div>
                    );
                },
            },
            {
                field: 'slug',
                headerName: 'Slug',
                flex: 5,
                disableColumnMenu: true,
            },
            {
                field: 'count',
                headerName: 'Count',
                flex: 1,
                disableColumnMenu: true,
            },
            {
                field: 'actions',
                headerName: 'Action',
                type: 'actions',
                flex: 1,
                getActions: (params) => [
                    <GridActionsCellItem
                        label="Details"
                        onClick={handleDetails(params.id, params.row.entity)}
                        showInMenu
                    />,
                ],
            },
        ],
        [handleDetails]
    )

    const fetchGlobalStats = async (p, by) => {
        return await axios.get(`/api/v1/stats/`, {
            params: {
                s: moment(p[0]).format('YYYY-MM-DD'),
                e: moment(p[1]).format('YYYY-MM-DD'),
                by: by
            }
        })
    }

    const handlePeriodChange = () => {
        dispatch(setPeriod(value))
        fetchGlobalStats(value, by).then(res => {
            setData(res.data)
        }).catch(err => {
            console.log('Error: ', err)
        })
    }

    const handleEntityChange = (event) => {
        by = event.target.value
        setHideActions(by !== 'tracks')
        setTag(by)
        localStorage.setItem('tag', by)
        fetchGlobalStats(value, by).then(res => {
            setData(res.data)
        }).catch(err => {
            console.log('Error: ', err)
        })
    }

    return (
        <>
            <div className="navbar">
                <h1 className='logo'>DingaStream</h1>
                {/*<span className="links">*/}
                {/*    <p>CHARTS</p>*/}
                {/*    <p>MUSIQUE</p>*/}
                {/*    <p>CULTURE</p>*/}
                {/*    <p>MEDIA</p>*/}
                {/*</span>*/}

                <div className="flex ml-auto">
                    <button className="btn-outline">LOGIN</button>
                    <button className="btn-primary">S'INSCRIRE</button>
                </div>
            </div>
            <div className='page'>
                <h1 className=''>Statistiques</h1>
                <div className="flex my-1 items-center">
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                        <DateRangePicker
                            startText="Début"
                            endText="Fin"
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            onClose={handlePeriodChange}
                            renderInput={(startProps, endProps) => {
                                // let date = `${startProps.inputProps.value} ~ ${endProps.inputProps.value}`
                                // date = date == ' ~ ' ? '' : date
                                // let {inputProps, ...prop} = startProps
                                // inputProps.value = date
                                return (
                                    <React.Fragment>
                                        <TextField {...startProps}/>
                                        <Box sx={{mx: 2}}> - </Box>
                                        <TextField {...endProps}/>
                                    </React.Fragment>
                                )
                            }}
                        />
                    </LocalizationProvider>
                    <FormControl>
                        <RadioGroup className='ml-2' onChange={handleEntityChange} row aria-labelledby="filters"
                                    defaultValue="tracks" name="filters">
                            <FormControlLabel value="tracks" control={<Radio/>} label="Tracks"/>
                            <FormControlLabel value="artists" control={<Radio/>} label="Artistes"/>
                            <FormControlLabel value="genres" control={<Radio/>} label="Genres"/>
                            <FormControlLabel value="albums" control={<Radio/>} label="Albums"/>
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className="mt-4">
                    <DataGrid
                        rows={data}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        autoHeight={true}
                        // checkboxSelection={true}
                        disableColumnFilter={false}
                        components={{Toolbar: CustomToolbar}}
                        initialState={{
                            sorting: {
                                sortModel: [{field: 'count', sort: 'desc'}],
                            },
                        }}
                        columnVisibilityModel={{
                            actions: hideActions
                        }}
                        componentsProps={{
                            panel: {
                                anchorEl: filterButtonEl,
                            },
                            toolbar: {
                                setFilterButtonEl,
                            },
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default Statistics